import React, { useContext, useState } from "react";
import {
  AuthenticationContext,
  EverButton,
  EverSelector,
  PageContainer,
  DragAndDropFile,
} from "elements.ui";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ClaimsImportRequestDTO } from "../Types/ClaimsImportRequestDTO";
import { ClaimsApi } from "../api/claimsApi";
import { EversureProducts } from "../Types/EversureProducts";
import { ClaimsFileUploadRequestDTO } from "../Types/ClaimsFileUploadRequestDTO";
import { getStorageAccountLink } from "../Types/StorageAccountLink";

const breadcrumbs = [
  { label: "Home", link: "/", absolute: true },
  { label: "Claims Bordereaux Import", isActive: true },
];

const defaultUploadRequest: ClaimsFileUploadRequestDTO = {
  ContainerName: "claims-bordereau-import",
  File: null,
};

const defaultRequest: ClaimsImportRequestDTO = {
  ContainerName: "claims-bordereau-import",
  ProductId: "",
  Importer: "Axa",
  BatchSize: 50,
  Sheet: "",
  Route: "",
};

const ClaimsBordereauxImport = () => {
  const [uploadRequest, setUploadRequest] =
    useState<ClaimsFileUploadRequestDTO>(defaultUploadRequest);
  const [importRequest, setImportRequest] =
    useState<ClaimsImportRequestDTO>(defaultRequest);
  const [waiting, setWaiting] = useState(false);
  const [sheets, setSheets] = useState([]);
  const [route, setRoute] = useState(null);

  const { getUser } = useContext(AuthenticationContext);

  const updateUploadRequest = (field: string, value: any) => {
    const tempRequest: any = { ...uploadRequest };

    tempRequest[field] = value;

    setUploadRequest(tempRequest);
  };

  const updateImportRequest = (field: string, value: any) => {
    const tempRequest: any = { ...importRequest };

    tempRequest[field] = value;

    setImportRequest(tempRequest);
  };

  const handleFileChange = (fileList: any) => {
    updateUploadRequest("File", fileList[0]);
  };

  const handleOnUpload = () => {
    if (uploadRequest.File == null) return;
    setWaiting(true);
    getUser().then((user: any) => {
      ClaimsApi.upload(
        uploadRequest.File,
        uploadRequest.ContainerName,
        user.access_token
      )
        .then((result: any) => {
          setRoute(result.data.route);
          updateImportRequest("Route", result.data.route);
          setSheets(
            result.data.sheets.map((sheet: string) => ({
              name: sheet,
              value: sheet,
            }))
          );
          setWaiting(false);
        })
        .catch(() => setWaiting(false));
    });
  };

  const handleOnStart = () => {
    setWaiting(true);
    getUser().then((user: any) => {
      ClaimsApi.import(importRequest, user.access_token).then((result: any) =>
        setWaiting(false)
      );
    });
  };

  return (
    <PageContainer
      heading="Import Claims From Underwriter Bordereaux"
      breadcrumbs={breadcrumbs}
      className=""
      rest=""
    >
      <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            To trigger the claims import from the bordereaux, select the
            bordereaux file and choose the target storage container for the file
            in Elements{" "}
            <a href={getStorageAccountLink()} target="_blank" rel="noreferrer">
              main storage account
            </a>
          </Typography>
          <Box mt={5} mb={5}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 6 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Target Container Name"
                  value={uploadRequest.ContainerName}
                  onChange={(e) => {
                    updateUploadRequest("ContainerName", e.target.value);
                    updateImportRequest("ContainerName", e.target.value);
                  }}
                  disabled={waiting}
                />
              </Grid>
              <Grid size={{ xs: 4 }}>
                <DragAndDropFile
                  height={55}
                  onUpload={handleFileChange}
                  acceptFiles=".xlsx"
                  readOnly={false}
                ></DragAndDropFile>
              </Grid>
              <Grid size={{ xs: 2 }}>
                <Box mt={2}>
                  <EverButton
                    onClick={handleOnUpload}
                    variant="contained"
                    color="primary"
                    disabled={waiting}
                  >
                    Upload
                  </EverButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {route && (
            <>
              <Typography variant="body1" gutterBottom>
                Now let's select the type of product, the importer, and any
                worksheets we will use to process the claims file for{" "}
                <strong>{route}</strong>
              </Typography>
              <Box mt={5} mb={5}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 3 }}>
                    <EverSelector
                      options={EversureProducts}
                      value={importRequest.ProductId}
                      onChange={(value) =>
                        updateImportRequest("ProductId", value.toString())
                      }
                      disabled={waiting}
                      label="Product"
                    />
                  </Grid>
                  <Grid size={{ xs: 3 }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Importer"
                      value={importRequest.Importer}
                      onChange={(e) =>
                        updateImportRequest("Importer", e.target.value)
                      }
                      disabled={waiting}
                    />
                  </Grid>
                  {sheets.length > 0 && (
                    <Grid size={{ xs: 3 }}>
                      <EverSelector
                        options={sheets}
                        value={importRequest.Sheet}
                        onChange={(value) =>
                          updateImportRequest("Sheet", value.toString())
                        }
                        disabled={waiting}
                        label="Worksheet"
                      />
                    </Grid>
                  )}
                  <Grid size={{ xs: 2 }}>
                    <Box mt={2}>
                      <EverButton
                        onClick={handleOnStart}
                        variant="contained"
                        color="primary"
                        disabled={waiting}
                      >
                        Start Import
                      </EverButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default ClaimsBordereauxImport;
