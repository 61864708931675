import axios from "axios";
import { createAuthHeader } from "elements.ui";
import { ClaimsImportRequestDTO } from "../Types/ClaimsImportRequestDTO";

const baseUrl = `${process.env.REACT_APP_CUSTOMER_MANAGEMENT_URL}/api/claims-import`;

export class ClaimsApi {
  static upload = (file: Blob, container: string, accessToken: string) => {
    var formData = new FormData();

    formData.append("file", file);
    formData.append("container", container);

    return axios.post(`${baseUrl}/upload-claims-file`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  static import = (request: ClaimsImportRequestDTO, accessToken: string) => {
    return axios.post(`${baseUrl}`, request, createAuthHeader(accessToken));
  };
}
