import React from "react";
import { PageContainer } from "elements.ui";
import { Card, CardContent, Typography } from "@mui/material";

const HomePage = () => {
  const breadcrumbs = [{ label: "Home", isActive: true, link: "/" }];

  return (
    <PageContainer heading="Home" breadcrumbs={breadcrumbs} className="" rest="">
      <Card>
        <CardContent>
          <Typography variant="h1" component="h2" gutterBottom>Welcome to Elements</Typography>
          <Typography variant="body1" gutterBottom>Elements is an advanced internal management tool developed by Eversure, designed to optimize the handling of customer profiles, insurance policies, quotes, and renewal processes.
             While it is an internal tool not accessible to customers, it is also utilized by Eversure's partners, enabling them to efficiently manage their collaborative operations.</Typography>
          <p>&nbsp;</p>
          <Typography variant="body1" gutterBottom>This robust platform ensures that both Eversure and its partners can maintain high standards of service delivery, operational excellence, and customer satisfaction, all within a secure and private data environment.</Typography>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default HomePage;
