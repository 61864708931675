import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  AuthenticationContextProvider,
  Callback,
  Logout,
  LogoutCallback,
  PrivateRoute,
  RootElementWrapper,
  sidebarNavigationItems,
  SilentRenew,
} from "elements.ui";
import "./main.scss";
import HomePage from "./pages/HomePage";
import BulkPolicyImport from "./pages/BulkPolicyImport";
import ClaimsBordereauxImport from "./pages/ClaimsBordereauxImport";

const App = () => {
  console.info(sidebarNavigationItems);
  return (
    <AuthenticationContextProvider>
      <Router>
        <RootElementWrapper navigationItems={sidebarNavigationItems}>
          <Routes>
            <Route path="/signin-oidc" element={<Callback />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/logout/callback" element={<LogoutCallback />} />
            <Route path="/silentrenew" element={<SilentRenew />} />
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path={process.env.PUBLIC_URL + "/home/policy-import"}
              element={
                <PrivateRoute>
                  <BulkPolicyImport />
                </PrivateRoute>
              }
            />
            <Route
              path={process.env.PUBLIC_URL + "/home/claims-bordereaux-import"}
              element={
                <PrivateRoute>
                  <ClaimsBordereauxImport />
                </PrivateRoute>
              }
            />
          </Routes>
        </RootElementWrapper>
      </Router>
    </AuthenticationContextProvider>
  );
};

export default App;
