import { EverFetch } from "elements.ui";
import { PolicyImportFullContainerRequestDTO } from "../Types/PolicyImportFullContainerRequestDTO";

export class PolicyImportService {
    private EndpointUrl = `${process.env.REACT_APP_CUSTOMER_MANAGEMENT_URL}/api/policy-import`;

    constructor(private everFetch: EverFetch) { }

    getNumberOfFiles = (containerName: string, folder?: string) => this.everFetch.Get<number>(`${this.EndpointUrl}/count-policies?Name=${containerName}&Folder=${folder}`);
    importFiles = (request: PolicyImportFullContainerRequestDTO) => this.everFetch.Post<string>(`${this.EndpointUrl}/import-full-container`, request);
}